<template>
  <el-card class="box-card full-screen">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item v-if="loginInfo.orgType==1" :label="$t('affiliationOrg')">
          <el-select v-model="queryForm.orgInfoCode" :placeholder="$t('all')" filterable size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option v-for="(t,i) in orgList" :key="i" :label="t.name+' -- '+(t.orgInfoCode)" :value="t.orgInfoCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('loginAccount')">
          <el-input v-model="queryForm.loginName" size="small"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('userName')">
          <el-input v-model="queryForm.name" size="small"></el-input>
        </el-form-item> -->
        <el-form-item :label="$t('accountStatus')">
          <el-select v-model="queryForm.status" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('normal')" value="0"></el-option>
            <el-option :label="$t('lock')" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isloading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
      <el-row class="table-btns">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="editAccount()">{{$t('addAccount')}}</el-button>
        <el-button type="text" size="mini" icon="el-icon-upload2" @click="onSetWorking(0)">{{$t('be on duty')}}</el-button>
        <el-button type="text" size="mini" icon="el-icon-download" @click="onSetWorking(3)">{{$t('off duty')}}</el-button>
      </el-row>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isloading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%" ref="dataTableSelection">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" fixed="left" :label="$t('orgMember')" width="180">
          <template slot-scope="scope">
            {{scope.row.name}} {{scope.row.surname}}
          </template>
        </el-table-column>
        <el-table-column v-if="loginInfo.orgType==1" prop="orgName" fixed="left" :label="$t('affiliationOrg')" width="200">
          <template slot-scope="scope">
            {{scope.row.orgName}} ({{scope.row.orgInfoCode}})
          </template>
        </el-table-column>
        <el-table-column prop="loginName" :label="$t('loginAccount')" width="160"></el-table-column>
        <el-table-column prop="job" :label="$t('post')" width="160"></el-table-column>
        <el-table-column prop="phoneNo" :label="$t('linkPhone')" width="160"></el-table-column>
        <el-table-column prop="idCard" :label="$t('idNumber')" width="180"></el-table-column>
        <el-table-column prop="status" :label="$t('accountStatus')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="text-success">{{$t('normal')}}</span>
            <span v-if="scope.row.status==1" class="text-danger">{{$t('lock')}}</span>
            <span v-if="scope.row.status==3" class="text-danger">{{$t('off duty')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="roleName" :label="$t('role')" width="180"></el-table-column>
        <el-table-column prop="remark" :label="$t('remark')" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" :label="$t('creationTime')" width="200">
          <template slot-scope="scope">{{$manba(scope.row.createTime).format('ymdhms')}}</template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('extend')" width="320">\
          <template slot-scope="scope">
            <el-button size="mini" @click="editAccount(scope.row)" type="primary">{{$t('edit')}}</el-button>
            <el-button size="mini" @click="updatePasswordVisible(scope.row)" type="primary">{{$t('resetPasswords')}}</el-button>
            <el-button v-if="scope.row.status==0" @click="onLock(scope.row,1)" size="mini" type="primary">{{$t('lock')}}</el-button>
            <el-button v-if="scope.row.status==1" @click="onLock(scope.row,0)" size="mini" type="warning">{{$t('unlock')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <el-drawer :title="editForm.id?$t('editAccount'):$t('addAccount')" :visible.sync="drawer" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="700">
      <el-form :model="editForm" :rules="rules" ref="editForm">
        <el-row :gutter="20" v-if="stepsActive==0">
          <div v-if="!editForm.id">
            <el-col :span="12">
              <el-form-item :label="$t('loginAccount')" prop="loginName">
                <el-input size="medium" v-model="editForm.loginName" :placeholder="$t('loginAccount')"></el-input>
              </el-form-item>
            </el-col>
            <!-- 新增账户时显示 -->
            <el-col :span="12">
              <el-form-item :label="$t('loginPassword')" prop="password">
                <el-input size="medium" v-model="editForm.password" maxlength="18" :placeholder="$t('notLoginPassword')"></el-input>
              </el-form-item>
            </el-col>
          </div>
          <el-col :span="12">
            <el-form-item :label="$t('user')+$t('lastName')" prop="surname">
              <el-input size="medium" v-model="editForm.surname" :placeholder="$t('lastName')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('user')+$t('firstName')" prop="name">
              <el-input size="medium" v-model="editForm.name" :placeholder="$t('firstName')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('post')" prop="job">
              <el-input size="medium" v-model="editForm.job" :placeholder="$t('post')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('idCard')" prop="idCard">
              <el-input size="medium" v-model="editForm.idCard" :placeholder="$t('idCard')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('linkPhone')" prop="phoneNo">
              <el-input size="medium" v-model="editForm.phoneNo" :placeholder="$t('phone')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('role')" prop="roleId">
              <el-select size="medium" v-model="editForm.roleId" filterable :placeholder="$t('searchable')" style="width:100%;">
                <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('org')+$t('remark')" prop="remark">
              <el-input size="medium" type="textarea" rows="3" v-model="editForm.remark" :placeholder="$t('org')+$t('remark')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="bottom-button">
          <el-button v-if="editForm.id" size="medium" type="primary" :loading="isLoading" @click="onUpdateSubmit('editForm')">{{$t('submit')+$t('update')}}</el-button>
          <el-button v-else size="medium" type="primary" :loading="isLoading" @click="onSubmit('editForm')">{{$t('submit')}}</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <!-- 锁定/解锁账户弹框 -->
    <el-drawer :title="$t(lockAccount.form.status==1?'lockAccount':'unlockAccount')" :visible.sync="lockAccount.drawer" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="700">
      <el-form :model="lockAccount.form" :rules="rules" ref="lockAccount">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('userName')">
              <el-input v-model="lockAccount.row.userName" size="small" disabled></el-input>
            </el-form-item>
            <el-form-item :label="$t('post')">
              <el-input v-model="lockAccount.row.job" size="small" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('employeesAccount')">
              <el-input v-model="lockAccount.row.loginName" size="small" disabled></el-input>
            </el-form-item>
            <el-form-item :label="$t('linkPhone')">
              <el-input v-model="lockAccount.row.phoneNo" size="small" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item :label="$t('reason')" prop="casue">
          <el-select v-model="lockAccount.form.casue" filterable>
            <el-option :label="'原因1'" :value="'原因1'"></el-option>
            <el-option :label="'原因2'" :value="'原因2'"></el-option>
            <el-option :label="'原因3'" :value="'原因3'"></el-option>
            <el-option :label="'原因4'" :value="'原因4'"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('remark')" prop="remark">
          <el-input type="textarea" :row="2" v-model="lockAccount.form.remark"></el-input>
        </el-form-item>
      </el-form>
      <div class="bottom-button">
        <el-button size="medium" type="primary" :loading="isLoading" @click="onSubmitLock('lockAccount')">{{$t(lockAccount.form.status==1?'lockAccount':'unlockAccount')}}</el-button>
      </div>
    </el-drawer>
  </el-card>
</template>
<script>
import org from '@/api/org'
import role from '@/api/role'
import verification from '@/assets/uilt/verification'
export default {
  name: "employeesAccount",
  data () {
    return {
      queryForm: {},
      editForm: {},
      roleList: new Array(),
      //table高度，动态设置
      queryTableHeight: String,
      //table显示内容
      tableData: {},
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,
      //显示加载中
      isloading: false,
      //右侧弹框
      drawer: false,
      //步骤条
      stepsActive: 0,
      isLoading: false,
      orgList: [],

      lockAccount: {
        drawer: false,
        form: {},
        row: {}
      },
    };
  },
  computed: {
    rules () {
      let verify = new verification(this)
      return {
        orgName: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        password: [{ required: true, validator: verify.password, trigger: 'blur' }],
        loginName: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        job: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        surname: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        name: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        idCard: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        phoneNo: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        roleId: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        casue: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    },
    loginInfo () { return this.$store.state.globalParameter.loginInfo; }
  },
  watch: {
    roleList () {
      this.$nextTick(function () { this.formatyTableDataRole(this.roleList); })
    },
    tableData () {
      this.$nextTick(function () { this.formatyTableDataRole(this.roleList); })
    }
  },
  methods: {
    getList () {
      let _this = this;
      _this.isloading = true;
      org.getOrgUserPage({
        param: { current: _this.currentPage, size: _this.pageSize, ..._this.queryForm },
        success: res => {
          _this.tableData = res.data || {};
          _this.isloading = false;
        }
      })
    },
    getRoleList () {
      let _this = this;
      role.getAllRole({
        success: res => {
          if (res.code != 200) return;
          _this.roleList = res.data;
        }
      })
    },
    /**格式化列表角色 */
    formatyTableDataRole (roles) {
      let _this = this;
      if (!this.tableData.records) return;

      this.tableData.records.forEach(function (t) {
        let role = roles.find(function (t2) { return t.roleId == t2.id; });
        if (role)
          _this.$set(t, 'roleName', role.name);
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    query () { this.getList(); },
    editAccount (row) {
      this.drawer = true;
      this.editForm = row || {};
    },
    onSubmit (formRef) {
      let _this = this;
      _this.$refs[formRef].validate(valid => {
        if (valid) {
          _this.isLoading = true;
          org.addOrgUser({
            param: _this[formRef],
            success: res => {
              if (res.code === 200) {
                _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
                _this.drawer = false;
                _this.getList();
              }
              else _this.$message({ type: "warning", message: _this.$t(res.msg) });
              _this.isLoading = false;
            }
          })
        }
        else {
          return false
        }
      })
    },
    onUpdateSubmit (formRef) {
      let _this = this;
      _this.$refs[formRef].validate(valid => {
        if (valid) {
          _this.isLoading = true;
          if (!_this.editForm.password)
            _this.editForm.password = undefined;

          org.updateOrgUser({
            param: _this[formRef],
            success: res => {
              if (res.code === 200) {
                _this.drawer = false;
                _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
              }
              else _this.$message.warning(_this.$t(res.msg));
              _this.isLoading = false;
            }
          })
        }
        else {
          return false
        }
      })
    },
    /**重置登录密码 */
    updatePasswordVisible (row) {
      this.$confirm(`${this.$t('ifThePsswordIsResetSuccessfullyItWillBeTheSameAsTheLoginAccount')}，${this.$t('whetherToResetThe')}?`, this.$t('resetLoginPassword'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        let _this = this;
        _this.isLoading = true;
        org.updateOrgUser({
          param: { id: row.id, password: row.loginName },
          success: res => {
            if (res.code === 200) {
              _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
            }
            else _this.$message({ type: "warning", message: _this.$t(res.msg) });
            _this.isLoading = false;
          }
        })
      }).catch(() => { this.$message({ type: 'info', message: '取消重置' }); });
    },
    getOrgList () {
      let _this = this;
      org.getOrgInfoPage({
        param: { current: 1, size: 10000, },
        success: res => {
          if (res.data)
            _this.orgList = res.data.records;
        }
      })
    },
    onLock (row, status) {
      console.log(row)
      row.userName = row.name + ' ' + row.surname;
      this.lockAccount.row = row;
      this.lockAccount.form = { orgUserId: row.id, status: status };
      this.lockAccount.drawer = true;
    },
    onSubmitLock (refName) {
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        org.lockOrgUser({
          param: this.lockAccount.form,
          success: res => {
            if (res.code == 200) {
              this.getList();
              this.lockAccount.drawer = false;
              this.$message.success(this.$t('operateSuccessfully'));
            }
            else
              this.$message.warning(this.$t(res.msg));
          }
        })
      })
    },

    /**设置上下班 */
    onSetWorking (status) {
      // console.log(this.$refs.dataTableSelection.selection);
      let list = this.$refs.dataTableSelection.selection;
      let arr = [];
      for (let i in list) {
        if (list[i].status == 1)
          continue;
        arr.push(list[i].id)
      }

      org.batchOffWork({
        param: { ids: arr.toString(), status: status }, success: res => {
          if (res.code == 200) {
            for (let i in list) {
              if (list[i].status == 1)
                continue;
              list[i].status = status
            }
          }
          else {
            this.$message({ type: 'error', message: this.$t(res.msg) + res.code })
          }
        }
      })
    },
  },
  created () { },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';
    this.getList();
    this.getRoleList();
    if (this.loginInfo.orgType == 1) {
      this.getOrgList();
    }
  },
}
</script>

<style lang="less" scoped>
</style>